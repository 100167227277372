header {
  height: 100vh;
  padding-top: 2rem;
  overflow: hidden;
}
.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}
/*+++++++++++++++++CTA++++++++++++++++*/
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
}
/*+++++++++++++++++Header socials ++++++++++++++++*/
.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header_socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
} /*+++++++++++++++++ME++++++++++++++++*/
.me {
  background: var(--color-primary);
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 25.9rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
}
/*+++++++++++++++++Scroll Down++++++++++++++++*/
.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1.5rem;
}
/******MEDIA QUERIES(MEDIUM DEVICES)****************/
@media screen and (max-width: 1024px) {
  header {
    height: 110vh;
  }
}
/******MEDIA QUERIES(SMALL DEVICES)****************/
@media screen and (max-width: 600px) {
  header {
    height: 120vh;
  }

  .header_socials,
  .scroll_down {
    display: none;
  }
}
